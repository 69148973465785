<script setup lang="ts">
import { BaseFetchWrapper } from '@legacy-studio/library';
import { useEventListener } from '@vueuse/core';
import { useGetCategories } from '../core/application';
import { usePaymentMethodsCategories } from '../infrastructure/store';
import { UiDarkBBButton } from '~/src/shared/ui';
import { savePaymentMethod } from '~/src/features/top-up/infrastructure/store';
import type { PaymentMethodItem } from '~/src/features/payment-method';
import { useSidebarStore } from '~/src/widgets/terminal/store';
import { TraderDrawers } from '~/src/widgets/terminal/enum';
import { saveWithdrawalMethod } from '~/src/features/withdrawal/infrastructure/store';

const { error, pending } = await useLazyAsyncData(useGetCategories());
const categories = usePaymentMethodsCategories();

const filterCategory = ref('');
const filteredCategories = computed(() => {
	if (!filterCategory.value) {
		return categories.value;
	}
	else {
		return (
			categories.value?.filter(
				category => category.code === filterCategory.value,
			) || []
		);
	}
});
const sidebarStore = useSidebarStore();
const { activeDrawer } = storeToRefs(sidebarStore);
const text = computed(() => activeDrawer.value === TraderDrawers.PaymentMethod ? 'Выберите удобный платежный метод' : 'Выберите удобный способ вывода');
const handleClick = (paymentMethod: PaymentMethodItem) => {
	savePaymentMethod(paymentMethod);
	saveWithdrawalMethod(paymentMethod);
	sidebarStore.goBack();
};

const scrollContainer = ref<HTMLElement | null>(null);

const isDragging = ref(false);
const startX = ref(0);
const scrollLeft = ref(0);

const onPointerDown = (event: PointerEvent) => {
	if (!scrollContainer.value) return;

	isDragging.value = true;
	scrollContainer.value.classList.add('cursor-grabbing');
	scrollContainer.value.setPointerCapture(event.pointerId);
	startX.value = event.pageX - scrollContainer.value.offsetLeft;
	scrollLeft.value = scrollContainer.value.scrollLeft;

	event.preventDefault();
};

const onPointerMove = (event: PointerEvent) => {
	if (!isDragging.value || !scrollContainer.value) return;

	const x = event.pageX - scrollContainer.value.offsetLeft;
	const walk = x - startX.value;
	scrollContainer.value.scrollLeft = scrollLeft.value - walk;
};

const onPointerUp = (event: PointerEvent) => {
	if (!isDragging.value || !scrollContainer.value) return;

	isDragging.value = false;
	scrollContainer.value.classList.remove('cursor-grabbing');
	scrollContainer.value.releasePointerCapture(event.pointerId);
};
const onWheel = (event: WheelEvent) => {
	if (event.deltaY !== 0) {
		event.preventDefault();
		scrollContainer.value?.scrollBy({
			left: event.deltaY,
			behavior: 'smooth',
		});
	}
};
useEventListener(scrollContainer, 'pointerdown', onPointerDown);
useEventListener(scrollContainer, 'pointermove', onPointerMove);
useEventListener(scrollContainer, 'pointerup', onPointerUp);
useEventListener(scrollContainer, 'wheel', onWheel);
</script>

<template>
	<BaseFetchWrapper
		:pending="pending"
		:error="error"
	>
		<div class="flex flex-col">
			<span class="text-sm font-normal text-gray-500">
				{{ text }}
			</span>

			<div
				ref="scrollContainer"
				class="flex gap-4 overflow-auto whitespace-nowrap py-6 px-4 -mx-4 scrollbar-hide cursor-grab"
			>
				<UiDarkBBButton
					:outline="!!filterCategory"
					size="sm"
					button-text="Все"
					class="!rounded-full"
					@click="filterCategory = ''"
				/>

				<UiDarkBBButton
					v-for="button in categories"
					:key="button.code"
					size="sm"
					:outline="filterCategory !== button.code"
					:button-text="button.name"
					class="!rounded-full"
					@click="filterCategory = button.code"
				/>
			</div>

			<div class="flex flex-col gap-6">
				<div
					v-for="section in filteredCategories"
					:key="section.id"
					class="flex flex-col gap-6"
				>
					<span class="text-base font-semibold text-white">
						{{ section.name }}
					</span>

					<div class="flex flex-col gap-3">
						<button
							v-for="item in section.items"
							:key="item.id"
							type="button"
							class="bg-gray-600 rounded-lg p-3 flex items-center gap-3"
							@click="handleClick(item)"
						>
							<img
								v-if="item.image"
								:src="item.image"
								:alt="item.image"
								class="w-[24px] h-[24px] object-contain"
							>
							<div
								v-else
								class="w-[24px] h-[24px]"
							/>

							<span class="text-base font-bold text-white">
								{{ item.name }}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</BaseFetchWrapper>
</template>
